<template>
    <div class="mb-50">
        <div class="list-z">
            <el-breadcrumb separator-class="el-icon-arrow-right" class="fs-18">
                <el-breadcrumb-item :to="{ path: '/' }"
                    >首页</el-breadcrumb-item
                >
                <el-breadcrumb-item
                    v-if="!!previousRoute.value"
                    :to="{ path: previousRoute.value }"
                    >{{ previousRoute.label }}</el-breadcrumb-item
                >
                <el-breadcrumb-item>企业详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <detail-page v-loading="loadingStatus"
            v-if="showDetail"
            :cards="cards"
            :title="title"
            :actions="actions"
            @action="onAction"
        />
        <el-tabs
            type="card"
            stretch
            v-model="activeTab"
            @tab-click="handleTabClick"
        >
            <el-tab-pane label="企业资质资格" name="tab1">
                <generic-table
                    :get-data="getPageDataQualification"
                    :columns="displayColumnsQualification"
                    :page-size="size"
                    :hide-pagination="false"
                    :headerCellBkcolor="'#F8F8F8'"
                    @linkAction="onLinkAction"
                ></generic-table>
            </el-tab-pane>
            <el-tab-pane label="注册人员" name="tab2">
                <generic-table
                    :get-data="getPageDataPerson"
                    :columns="displayColumnsPerson"
                    :page-size="size"
                    :hide-pagination="false"
                    :headerCellBkcolor="'#F8F8F8'"
                    @linkAction="onLinkAction"
                ></generic-table>
            </el-tab-pane>
            <el-tab-pane label="工程项目" name="tab3">
                <generic-table
                    :get-data="getPageDataProject"
                    :columns="displayColumnsProject"
                    :page-size="size"
                    :hide-pagination="false"
                    :headerCellBkcolor="'#F8F8F8'"
                    @linkAction="onLinkAction"
                ></generic-table>
            </el-tab-pane>
            <el-tab-pane label="不良行为" name="tab4"> 
                <div style="color: #888888;padding: 20px;text-align: center;">
                    暂无数据
                </div>
            </el-tab-pane>
            <el-tab-pane label="良好行为" name="tab5"> 
                <div style="color: #888888;padding: 20px;text-align: center;">
                    暂无数据
                </div>
            </el-tab-pane>
            <el-tab-pane label="黑名单记录" name="tab6"> 
                <div style="color: #888888;padding: 20px;text-align: center;">
                    暂无数据
                </div>
            </el-tab-pane>
            <el-tab-pane label="失信联合惩戒记录" name="tab7"> 
                <div style="color: #888888;padding: 20px;text-align: center;">
                    暂无数据
                </div>
            </el-tab-pane>
            <el-tab-pane label="变更记录" name="tab8"> 
                <div style="color: #888888;padding: 20px;text-align: center;">
                    暂无数据
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import GenericTable from "@/components/generic-table";
import { getDcEnterpriseHighPage } from "@/api/aptitude";
import DetailPage from "@/components/detail-page";
import {
    getDcEnterpriseDetail,
    getEnterprisePersonPage,
    getEnQualificationPage,
    getEnterpriseProjectInfoPage,
} from "@/api/aptitude";

export default {
    components: {
        GenericTable,
        DetailPage,
    },
    data() {
        return {
            loadingStatus: false,
            previousRoute: {
                label: "",
                value: "",
            },
            showDetail: true,
            cards: [],
            actions: [],
            title: "",
            size: 20,
            current: 1,
            activeTab: "tab1",
            unicode: "",
            fromProvince: "",
            enterpriseName: "",
            displayColumns: [
                {
                    label: "企业名称",
                    prop: "fullName",
                    minWidth: 25,
                },
                {
                    label: "统一社会信用代码",
                    prop: "unicode",
                    minWidth: 25,
                },
                { label: "企业法定代表人", prop: "legalPerson", minWidth: 20 },
                { label: "企业注册属地", prop: "provinceCity", minWidth: 20 },
            ],
            displayColumnsQualification: [
                {
                    label: "资质类别",
                    prop: "typeName",
                    minWidth: 25,
                },
                {
                    label: "资质证书号",
                    prop: "certno",
                    minWidth: 25,
                },
                { label: "资质名称", prop: "name", minWidth: 20 },
                { label: "发证日期", prop: "startdate", minWidth: 20 },
                { label: "发证有效期", prop: "enddate", minWidth: 20 },
                { label: "发证机关", prop: "dummyField", minWidth: 20 },
                { label: "预览", prop: "preview", minWidth: 20 },
            ],
            displayColumnsPerson: [
                {
                    label: "人员姓名",
                    prop: "name",
                    minWidth: 13,
                },
                { label: "性别", prop: "sex", minWidth: 13 },
                { label: "身份证号", prop: "no", minWidth: 13 },
                {
                    label: "注册号",
                    prop: "code",
                    minWidth: 13,
                },
                { label: "注册类别", prop: "typeName", minWidth: 13 },
                { label: "唯一号", prop: "keyno", minWidth: 13 },
            ],
            displayColumnsProject: [
                {
                    label: "项目名称",
                    prop: "prjname",
                    minWidth: 25,
                },
                {
                    label: "项目编号",
                    prop: "prjnum",
                    minWidth: 25,
                },
                { label: "项目类别", prop: "prjtypenum", minWidth: 20 },
                { label: "建设单位", prop: "buildcorpname", minWidth: 20 },
            ],
        };
    },
    mounted() {
        this.extractPreviousRoute();
        this.extractUnicode();
        this.getDetail();
    },
    methods: {
        extractPreviousRoute() {
            const matched = this.$route.matched;
            if (matched.length > 1) {
                const previousRoute = matched[matched.length - 2];
                const previousRouteName = previousRoute.name;
                console.log(
                    "extractPreviousRoute-->previousRouteName=" +
                        previousRouteName
                );
                let label = "",
                    value = "";
                if (previousRouteName == "qualification") {
                    label = "资质查询";
                    value = "/" + previousRouteName + "/index";
                }
                this.previousRoute.label = label;
                this.previousRoute.value = value;
            }
        },
        handleTabClick(tab) {
            console.log(tab.name);
        },
        onLinkAction(prop, row) {
            if (prop == "fullName") {
                console.log("linkAction", prop, row);
                //this.$router.push({path: "/qualification/detail/", query: { unicode: row.unicode }});
            }
        },
        redirectToCreditChina() {
            window.location.href = "https://www.creditchina.gov.cn/";
        },
        routeToRecord() {
            this.$router.push({path: "/record/detail/", query: { fromProvince: this.fromProvince, creditCode: this.unicode }});
        },
        routeToCreditScore() {
            this.$router.push({name: "creditscore-index", params: { fromProvince: this.fromProvince, creditCode: this.unicode }});
        },
        routeToProject() {
            this.$router.push({name: "project-index", params: { buildcorpname: this.enterpriseName }});
        },
        onAction(actValue) {
            if (actValue == 'creditChina') {
                this.redirectToCreditChina();
            } else if (actValue == 'record') {
                this.routeToRecord();
            } else if (actValue == 'creditscore') {
                this.routeToCreditScore();
            } else if (actValue == 'project') {
                this.routeToProject();
            }
        },
        extractUnicode() {
            const unicode = this.$route.query.unicode;
            this.unicode = unicode;
        },
        async getDetail() {
            if (this.loadingStatus) return;
            this.loadingStatus = true;
            try {
                const unicode = this.unicode;
                console.log(`unicode: ${unicode}`);
                const res = await getDcEnterpriseDetail({ unicode: unicode }); // 调用 API 获取明细信息
                const item = res ? res.data : {};
                this.title = `企业详情 - ${item.fullName}`;
                this.enterpriseName = item.fullName;
                this.fromProvince = item.province;
                let provinceCity =
                    item.province + (item.city ? "-" + item.city : "");
                this.cards = [
                    {
                        colsNum: 1,
                        title: item.fullName,
                        fields: [
                            {
                                label: "统一社会信用代码",
                                value: item.unicode,
                            },
                            {
                                label: "企业法定代表人",
                                value: item.legalPerson,
                            },
                            {
                                label: "企业注册资金",
                                value: item.regCapital,
                            },
                            {
                                label: "企业注册属地",
                                value: provinceCity,
                            },
                            {
                                label: "企业经营地址",
                                value: item.address,
                            },
                            {
                                label: "企业登记注册类型",
                                value: item.scope,
                            },
                        ],
                    },
                ];
                this.actions = [
                    { label: "信用中国", value: "creditChina" },
                    { label: "中标项目", value: "project" },
                    { label: "全国信用分", value: "creditscore" },
                    { label: "全国备案", value: "record" },
                ];
                this.showDetail = true; // 显示 DetailPage 组件
            } catch (error) {
                console.error(error);
                this.$message.error("获取企业信息失败");
            } finally {
                this.loadingStatus = false;
            }
        },
        async getPageData({ size, current }) {
            this.size = size;
            this.current = current;
            // 获取数据的 API 请求
            const response = await getDcEnterpriseHighPage({
                size,
                current,
                tags: "四库",
                name: this.searchValue,
            });

            let records = response.data ? response.data.records : [];
            let total = response.data ? response.data.total : 0;
            (records || []).forEach((item) => {
                item.provinceCity =
                    item.province + (item.city ? "-" + item.city : "");
            });
            return {
                data: records,
                total: total,
            };
        },
        jsonDecodeProfs(profs) {
            let ret = profs;
            try {
                let jsonObj = JSON.parse(profs);
                if (jsonObj && Array.isArray(jsonObj)) {
                    ret = jsonObj.join(',');
                }
            } catch(e) {
            }
            return ret;
        },
        async getPageDataPerson({ size, current }) {
            this.size = size;
            this.current = current;
            const unicode = this.$route.query.unicode || this.unicode;
            console.log(`getPageDataPerson->unicode: ${unicode}`);

            // 获取数据的 API 请求
            const response = await getEnterprisePersonPage({
                size,
                current,
                unicode,
            });

            let records = response.data ? response.data.records : [];
            let total = response.data ? response.data.total : 0;
            (records || []).forEach((item) => {
                item.profsName = item.profs ? (
                    Array.isArray(item.profs) ?  item.profs.join(",") : this.jsonDecodeProfs(item.profs)
                ) : '';
            });
            return {
                data: records,
                total: total,
            };
        },

        async getPageDataQualification({ size, current }) {
            this.size = size;
            this.current = current;
            const unicode = this.$route.query.unicode || this.unicode;
            console.log(`getPageDataQualification->unicode: ${unicode}`);

            // 获取数据的 API 请求
            const response = await getEnQualificationPage({
                size,
                current,
                unicode,
            });

            let records = response.data ? response.data.records : [];
            let total = response.data ? response.data.total : 0;
            (records || []).forEach((item) => {
                item.preview = item.certno ? "证书信息" : "";
            });
            return {
                data: records,
                total: total,
            };
        },

        async getPageDataProject({ size, current }) {
            this.size = size;
            this.current = current;
            const unicode = this.$route.query.unicode || this.unicode;
            console.log(`getPageDataProject->unicode: ${unicode}`);

            // 获取数据的 API 请求
            const response = await getEnterpriseProjectInfoPage({
                size,
                current,
                unicode,
            });

            let records = response.data ? response.data.records : [];
            let total = response.data ? response.data.total : 0;
            // (records || []).forEach((item) => {
            //     item.preview = item.certno ? '证书信息' : '';
            // });
            return {
                data: records,
                total: total,
            };
        },
    },
};
</script>
<style scoped>
.list-z {
    text-align: left;
    font-weight: 500;
    color: #4e4e4e;
    line-height: 40px;
    margin-top: 35px;
    margin-bottom: 25px;
}
.fs-18 {
    font-size: 18px;
}
.mb-50 {
    margin-bottom: 50px;
}
.table-header-area {
    display: flex;
}
.table-header {
    width: 520px;
    height: 60px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    text-align: left;
    margin-top: 32px;
    margin-bottom: 32px;
    padding-left: 20px;
}

.table-title {
    width: 233px;
    height: 24px;
    font-size: 24px;
    font-family: Source Han Sans CN-Regular;
    font-weight: 500;
    line-height: 60px;
}


.right-header {
    text-align: right;
    margin-top: 32px;
    margin-bottom: 32px;
    padding-right: 20px;
    flex: 1;
}
::v-deep .el-descriptions{
    margin-bottom: 20px;
}
::v-deep .el-tabs__nav.is-stretch > * {
    width: auto;
}
::v-deep .el-tabs__header {
    padding: 0;
    position: relative;
    margin: 0px 0 30px;
}
::v-deep .detail-card {
    overflow: hidden;
    margin-bottom: 30px;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item {
    flex: 1;
    text-align: center;
    width: auto;
    height: 48px;
    font-size: 20px;
    line-height: 48px;
}
::v-deep .detail-card-label {
    display: table-cell;
    width: 232px;
    font-size: 20px;
    color: #888888;
    padding-top: 16px;
    padding-bottom: 16px;
}
::v-deep .detail-card-value {
    padding-bottom: 15px;
}
::v-deep .el-table--enable-row-transition .el-table__body td.el-table__cell {
    font-size: 18px;
}
::v-deep .detail-card-value {
    font-size: 20px;
}
::v-deep .detail-card-title {
    margin-bottom: 20px;
}
::v-deep .detail-card-divider {
    margin-bottom: 30px;
}
</style>
